<template>
    <div class="memberClub">
        <!-- 会员俱乐部 -->
		<common-header bg_size="100% calc(env(safe-area-inset-top) + 2.95rem)" :bg_src="require('../../assets/imgs/new_color/club_bg.png')" :title="$t('system.MemberClub')" :is-return="true" style="padding-top: env(safe-area-inset-top);"></common-header>
		<div class="content">
			<van-row class="userInfo" type="flex" justify="center">
			    <van-col>
			        <!-- <img class="userHead" v-if="vipInfo.avatar" :src="vipInfo.avatar"/>
			        <img class="userHead" v-else src="@/assets/imgs/login/rainbow.png"/> -->
			        <img class="userHead" :src="vipInfo.avatar"/>
			    </van-col>
			    <van-col>
			        <div class="userName">{{username}}</div>
					<div class="level" v-if="vipInfo.active">
						<span>VIP {{ vipInfo.level | vip_level}}</span>
					</div>
			        <div class="vipBox">
			            <p class="expireDay" v-if="vipInfo.active">
			                <span class="span1">{{ vipInfo.expire_day }}</span><span class="span2">{{ $t('system.deadline')}}</span>
			            </p>
			            <p class="expireDay" v-else><span class="span2">{{ $t('system.youarenotVip')}}</span></p>
			        </div>
			    </van-col>
			</van-row>
			<div class="layoutBox">
				<div class="banli">
					<div class="top">
						<span class="kuai"></span>
						<p class="mcTitle">{{ $t('system.handlingMembership')}}</p>
					</div>
				    <van-row gutter="15">
				        <van-col
				                span="8"
				                v-for="(item, index) of dataList"
				                :key="index"
				                @click="ChangeBiZhong(index)"
				        >
				            <div class="changeStyles" :class="active == index ? 'checked' : ''">
				                <p class="dataTitle">{{ item.name }}</p>
				                <p class="dataPrice">{{ item.price }}</p>
				                <p class="dataPriceOld">{{ item.raw_price }}</p>
				            </div>
				        </van-col>
				    </van-row>
				    <van-button @click="showbuy" class="buttonOk">{{ $t('system.yes')}}</van-button>
				</div>
				
				<van-dialog
				        v-model="show"
				        show-cancel-button
				        :title="card_type"
				        @confirm="buyCard"
				>
				    <p class="diglogPStyle1">
						<span class="label">{{ $t('system.PurchaseQuantity') }}：</span>
						<input v-model="num" autofocus="autofocus" type="number" class="psdInput" />
					</p>
				    <p class="diglogPStyle1">
						<span class="label">{{ $t('system.AvailableBalance') }}：</span>
						<span style="color: #459c86;">{{ balance }}</span> USDT
				    </p>
				</van-dialog>
			</div>
		</div>
		
    </div>
</template>

<script>
	import commonHeader from '@/components/commonHeader';

    export default {
        name: "myActivationCode",
        components: {commonHeader},
        data() {
            return {
                num: 1,
                dataList: [],
                active: 0,
                tradepwd: "",
                balance: 0,
                username: '',
                type: 1,
                card_type: "",
                show: false,
				// vip会员信息
				vipInfo: {},
            };
        },
        mounted() {
            this.username = JSON.parse(localStorage.getItem('userInfo')).username || ''
            this.getListActiveCode()
            this.getClubMemberInfo();
            this.getMyAsset();
        },
		filters: {
			vip_level(v) {
				if(v == -1) {
					return 0
				}else {
					return v;
				}
			}
		},
        methods: {
			
			// 获取用户USDT的可用余额
			getMyAsset() {
			    this.$post2("User/Api/Index/userAsset", {})
			        .then((res) => {
			            this.balance = res.USDT.amount.toFixed(6);
			        })
			        .catch((e) => {
			            this.$toast.fail(e);
			        });
			},
			
			// 验证是否为会员用户，获取用户会员等级信息
			getClubMemberInfo() {
			    this.$post2("User/Api/Index/getClubInfo", {}).then((res) => {
					this.vipInfo = res
			    });
			},
			
			// 获取会员卡类型列表
			getListActiveCode() {
			    this.$post2("User/Api/Index/getMemberSettingV2").then((res) => {
			        this.dataList = res;
			    });
			},
			
			// 切换选取的会员卡类型
			ChangeBiZhong(index) {
			    this.active = index;
			    this.type = this.dataList[index].type;
			},
			// 获取选择的会员卡类型
			getType() {
			    this.type = this.dataList[this.active].type;
			    this.card_type = this.dataList[this.active].name;
			},
            
			// 点击确定办理，弹出办理会员数量输入框
            showbuy() {
                this.getType();
                this.show = true;
            },
			
			// 点击 办理会员 确定按钮，传递参数给接口
			buyCard() {
			    this.getType();
				
				let unitPrice = this.dataList[this.active].price.split('U')
				let vipFee = this.num * unitPrice[0]
				// console.log(vipFee)
				if (vipFee > this.balance) {
					this.$toast('余额不足')
					return
				}

				this.$router.push({
					path: '/checkClubVerifyCode',
					query: {
						type: this.type,
						num: this.num,
					}
				})
			},
			
        },
    };
</script>

<style lang="less" scoped>
    .memberClub {
		font-family: PingFangSC-Regular;
        .diglogPStyle {
            margin: 0.2rem 0.2rem 0.2rem 2rem;
			font-size: 16px;
        }
        .dialogStyle {
            margin: 0.4rem 0.4rem 0.1rem 0.4rem;
            padding:0 0 0.2rem;
            border-bottom: 1px solid #f3f0f3;
        }
        .diglogPStyle1{
            font-size: 16px;
            // margin: 0.4rem 0.4rem 0.1rem 0.4rem;
			margin: .3rem .4rem;
            // padding: 0 0 0.2rem;
			display: flex;
			.label {
				// width: 5em;
				width: 9em;
			}
			.psdInput {
				font-size: 16px;
				border: 0;
				flex: 1;
				display: block;
				width: 100%;
			}
        }

        .buttonOk{
            margin-top: 5.5rem;
            color: #333333;
			font-size: .32rem;
            border: 0;
            // border-radius: 5px;
            width: 100%;
			height: .88rem;
			line-height: .88rem;
			background: #FAD538;
			border-radius: .16rem;
        }
		.content {
			position: relative;
			// top: 0.88rem;
			padding-top: 0.88rem;
			padding-top: calc(env(safe-area-inset-top) + 0.88rem);
			left: 0;
			width: 100%;
			// z-index: 9999;
			&::before{
				content: "";
				height: 2.95rem;
				height: calc(env(safe-area-inset-top) + 2.95rem);
				width: 100%;
				background: url(../../assets/imgs/new_color/club_bg.png) no-repeat center top;
				background-size: 100% 100%;
				display: inline-block;
				position: absolute;
				top: 0;
			}
		}
        .userInfo {
			// margin-top: -100px;
            // border-bottom: 0.12rem solid #F5F5F5;
            overflow: hidden;
            margin: 0 0.5rem;
			margin-top: 0.2rem;
			// padding-top: 0.2rem;
			position: relative;
			z-index: 10;
			.level {
				margin-left: 0.2rem;
				span {
					width: 1.91rem;
					height: 0.37rem;
					border-radius: 0.27rem;
					font-size: 0.24rem;
					text-align: center;
					line-height: 0.37rem;
					color: #D7B986;
					background-image: url(../../assets/imgs/new_color/VIP_bg.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					padding: 0.03rem 0.17rem;
				}
			}

            .userHead {
                border-radius: 50%;
                width: 1.2rem;
                height: 1.2rem;
            }

            .userName {
				margin-left: 0.2rem;
                font-size: 0.32rem;
				// font-family: Bahnschrift;
				// line-height: .5rem;
				color: #6E471C;
                // font-weight: bold;
            }

            .vipBox {
				margin-left: 0.2rem;

                .vipLevel {
                    width: 1rem;
                    height: 0.4rem;
                    position: relative;
                    font-size: 0.24rem;
                    font-family: Bahnschrift;
                    display: inline-block;
                    color: #6E471C;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        position: relative;
                        z-index: 11;
                        display: block;
                        text-align: center;
                        line-height: 0.4rem;
                        font-weight: bold;
                        font-size: 0.26rem;
                    }
                }

                .expireDay {
                    // display: inline-block;
					color: #6E471C;
					line-height: .35rem;

                    .span1 {
                        font-size: 0.24rem;
                        // font-family: Bahnschrift;
                        font-weight: normal;
                    }

                    .span2 {
                        margin-left: 0.1rem;
                        font-size: 0.24rem;
                        // font-weight: 500;
                        // color: #737373;
                        // opacity: 0.7;
                    }
                }
            }
        }

        .banli {
            // padding: 0.5rem 0.3rem;
			margin: 0 0.2rem;
			margin-top: 0.7rem;

            .top {
            	// border-bottom: 0.01rem solid #D3D9DE;
            	padding: 0.2rem 0;
            	display: flex;
            	align-items: center;
            	.kuai {
            		margin-right: 0.22rem;
            		width: 5px;
            		height: 0.26rem;
            		background-color: #F8D33A;
            	}
            	.mcTitle {
            		font-size: 0.32rem;
            		font-family: PingFang SC;
            		font-weight: bold;
            		color: #333333;
            		// margin-bottom: .25rem;
            	}
            }
            .changeStyles{
                // border: 1px solid #94C7FC;
				border: 1px solid #EFBA0D;
                border-radius: 0.16rem;
                text-align: center;
                padding: 0.3rem 0;
				overflow: hidden;
				&.checked {
					color: #fff;
					background-image: linear-gradient(135deg, #FAE341 0%, #F9DB45 100%);
					background-color: ;
					position: relative;
					border: none;
					// &::after {
					// 	content: '';
					// 	position: absolute;
					// 	right: 0;
					// 	bottom: 0;
					// 	width: .6rem;
					// 	height: .6rem;
					// 	background: url(../../assets/imgs/icon/member_icon_bottom.png) no-repeat right bottom;
					// 	background-size: contain;
					// }
					.dataPrice{
						color: #333333;
					}
				}
            }
            .mainBackColor1 p{
                color: #fff;
            }
            .dataTitle{
				color: #333333;
                font-size: 0.28rem;
                // font-weight: bold;
                // color: #000000;
            }
            .dataPrice{
				color: #EFBA0D;
                font-size: 0.44rem;
                // font-weight: bold;
				// font-family: Bahnschrift;
				font-weight: bold;
				margin-top: 0.1rem;
            }
            .dataPriceOld{
				color: #666666;
                font-size: 0.28rem;
                // font-weight: 500;
				// font-family: Bahnschrift;
                text-decoration: line-through;
				margin-top: 0.1rem;
            }
        }
    }
</style>
